import { Link } from "react-router-dom";

import larr from '../images/icons/arrow_back_white_24dp.svg';
import uarr from '../images/icons/arrow_upward_white_24dp.svg';
import link from '../images/icons/social/link.svg';
import email from '../images/icons/social/email.svg';
import facebook from '../images/icons/social/facebook.svg';
import youtube from '../images/icons/social/youtube.svg';
import twitter from '../images/icons/social/twitter.svg';
import linkedin from '../images/icons/social/linkedin.svg';
import instagram from '../images/icons/social/instagram.svg';

const About = () => {
    return (
        <div className="app" role="main">
            <nav>
                <Link to="/" className="back">
                    <img src={larr} alt="Back" />
                </Link>
                <h1 className="title">About</h1>
            </nav>
            <article id="top" className="content page">
                <div className="ql">
                    <a href="#christiancarman">Christian Carman</a>
                    <a href="#breadoflife">Bread of Life</a>
                    <a href="#barbersden">Barber's Den</a>
                    <a href="#celerity">Celerity</a>
                </div>
                <section id="christiancarman">
                    <h2>Christian Carman</h2>
                    <hr></hr>
                    <p>Hi! I'm Christian, the man behind the beard. I'm the Head of System Integrations (a.k.a Head of Technology) at <a href="https://www.celerity-is.com/us/">Celerity</a> during the day and the creator / developer of Shave It for Charity at night. This website and charity event all came about because I had an idea when we first started quarantining in 2020 that I would let my beard grow out until the offices opened back up and then shave it off for charity. The fun part is letting my friends, family, and co-workers decide what shape I’ll shave it into with their donations while also helping support a local charity that I’m passionate about.</p>
                    <p>I can't thank you enough for your consideration and support for this event. Your contribution means the world to me; not only are you impacting my license renewal photo for the next 5 years (did I forget to mention I need to renew my license right after this event) but the volunteers at <a href="https://www.breadoflifemalden.org/">Bread of Life</a> will be able to help more people in need because of your generosity.</p>
                    <div className="social">
                        <a href="mailto:ccarman@shaveitforcharity.com"><img src={email} alt="Email"/></a>
                        <a href="https://www.youtube.com/channel/UC2U-XFB0UMyqGvEJHsukSZA"><img src={youtube} alt="YouTube"/></a>
                        <a href="https://twitter.com/CX3Tech"><img src={twitter} alt="Twitter"/></a>
                        <a href="https://www.linkedin.com/in/christiancarman/"><img src={linkedin} alt="LinkedIn"/></a>
                    </div>
                </section>
                <section id="breadoflife">
                    <h2>Bread of Life</h2>
                    <hr></hr>
                    <p>Bread of Life is a local volunteer organization serving the hungry, homeless, needy, and isolated. They offer free evening meals four nights a week, emergency food pantries, free clothing, grocery delivery to senior citizens in public housing, and food delivery to homeless families sheltered in local motels. They even collaborate with local agencies to help low-income residents obtain counseling, furniture, housing, and financial assistance. In short, they're amazing and you should definitely learn more about them.</p>
                    <div className="social">
                        <a href="https://www.breadoflifemalden.org/"><img src={link} alt="Website"/></a>
                        <a href="mailto:info@breadoflifemalden.org"><img src={email} alt="Email"/></a>
                        <a href="https://www.facebook.com/BreadofLifeMA"><img src={facebook} alt="Facebook"/></a>
                        <a href="https://www.youtube.com/channel/UC3y9ax87KPvYSK8gC4I994A?view_as=subscriber"><img src={youtube} alt="YouTube"/></a>
                        <a href="https://twitter.com/breadmalden"><img src={twitter} alt="Twitter"/></a>
                        <a href="https://www.linkedin.com/company/40711481"><img src={linkedin} alt="LinkedIn"/></a>
                        <a href="https://www.instagram.com/breadoflifemalden/"><img src={instagram} alt="Instagram"/></a>
                    </div>
                </section>
                <section id="barbersden">
                    <h2>Barber's Den</h2>
                    <hr></hr>
                    <p>Barber's Den was more than kind enough to donate their time and effort to this event as the skilled beard experts behind the razer. And thankfully they did because I am in no way qualified to even attempt to shave my beard into some of these styles.</p>
                    <p>Barber's Den opened in 2010 and has been a staple of Downtown Melrose. They originally started as a Walk-In-Only shop and would often see over 100 people walk through their doors every day. To grow and create a better balance for their barbers and clients they changed to appointment only (although they still take walk-ins based off availability). They have five barbers, all sharing the same passions: hair, quality, and having a good time.</p>
                    <div className="social">
                        <a href="https://barbersden.com/"><img src={link} alt="Website"/></a>
                        <a href="https://www.facebook.com/TheBarbersDen/"><img src={facebook} alt="Facebook"/></a>
                        <a href="https://instagram.com/barbersden"><img src={instagram} alt="Instagram"/></a>
                    </div>
                </section>
                <section id="celerity">
                    <h2>Celerity</h2>
                    <hr></hr>
                    <p>Celerity IS, Inc. is the place I call home from Monday to Friday and have for over 6 years. It’s thanks to all the support from Celerity, especially our General Manager, <a href="https://www.linkedin.com/in/susanmurray6/">Susan Murray</a>, that I was able to get in contact with the right people and have the time to get all of this setup.</p>
                    <p>Celerity is a group of world-leading Adobe Marketing Cloud Consultants. They architect, implement and orchestrate Adobe Marketing Cloud technology to enable businesses to create more rewarding customer relationships. They are top performing partners of Adobe, specialized in Adobe Campaign, and are experienced at getting the best from their solutions through expertise in custom integrations, product innovation and optimization.</p>
                    <div className="social">
                        <a href="https://www.celerity-is.com/us/"><img src={link} alt="Website"/></a>
                        <a href="mailto:inquiries@celerity-is.com"><img src={email} alt="Email"/></a>
                        <a href="https://www.facebook.com/celeritylife/"><img src={facebook} alt="Facebook"/></a>
                        <a href="https://www.linkedin.com/company/celerity-information-services"><img src={linkedin} alt="LinkedIn"/></a>
                        <a href="https://www.instagram.com/celerity_insta/"><img src={instagram} alt="Instagram"/></a>
                    </div>
                </section>
            </article> 
            <button className="btn totop" title="Top" onClick={() => {window.scrollTo(0, 0);}}><img src={uarr} alt="Top" /></button>
        </div>
    )
}

export default About;
