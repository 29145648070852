import celerity from '../images/logo_celerity_500x180.png';
import barbersden from '../images/logo_barbersden_500x180.png';
import bol from '../images/logo_breadoflife_500x300.png';

const Footer = () => {
    return (
        <div className="footer">
            <div className="content">
                <div className="sponsors">
                    <h2>Sponsored By</h2>
                    <hr></hr>
                    <div className="logos">
                        <div className="logo">
                            <a className="logo-split" href="https://www.celerity-is.com/us/">
                                <img src={celerity} alt="Celerity" />
                            </a>
                            <a href="https://barbersden.com/">
                                <img src={barbersden} alt="Barbers Den" />
                            </a>
                        </div>
                        <div className="logo">
                            <a className="logo-full" href="https://www.breadoflifemalden.org/">
                                <img src={bol} alt="Bread of Life" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="contacts">
                    <h2>Contact Us</h2>
                    <hr></hr>
                    <div className="contact">
                        <h4>Inquiries</h4>
                        <a href="mailto:inquiries@shaveitforcharity.com">inquiries@shaveitforcharity.com</a>
                    </div>
                    <div className="contact">
                        <h4>Support</h4>
                        <a href="mailto:support@shaveitforcharity.com">support@shaveitforcharity.com</a>
                    </div>
                    <div className="contact">
                        <h4>Bread of Life</h4>
                        <a href="mailto:info@breadoflifemalden.org">info@breadoflifemalden.org</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;